<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-btn
        flat
        label="back"
        color="primary"
        @click="$router.push('/statistikdaftarkamar/' + dataKamar.id_asrama)"
      ></q-btn>
      <q-separator></q-separator>
      <q-card-section class="q-px-md bg-grey-1">
        <q-markup-table flat wrap-cells class="bg-grey-1">
          <tr>
            <td>Kamar</td>
            <td>:</td>
            <td>{{ dataKamar.nama }}</td>
          </tr>
          <tr>
            <td>Ketua Kamar</td>
            <td>:</td>
            <td>{{ dataKamar.nama_ketua }}</td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-separator></q-separator>
      <!-- <q-card-section>
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>-->
      <q-markup-table
        separator="cell"
        class="stickyTable"
        style="height: calc(100vh - 200px)"
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Nama ({{ filSiswa.length }})</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataKamar: {},
      listAnggota: [],
      searchTerm: "",
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listAnggota;
      else {
        return this.listAnggota.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getDataKamar();
    await this.getAnggotaKamar();
    this.$q.loading.hide();
  },
  methods: {
    async getDataKamar() {
      let resp = await this.$http.get(
        `/statistik/kamar/getdata/${this.$route.params.id}`
      );
      this.dataKamar = resp.data;
    },
    async getAnggotaKamar() {
      let resp = await this.$http.get(
        `/statistik/kamar/getanggota/${this.$route.params.id}`
      );
      this.listAnggota = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
